
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    tag: {
      type: String as PropType<string>,
      default: "td",
      validator: (val: string): boolean => {
        const tags = ["td", "th"];
        return tags.indexOf(val) !== -1;
      },
    },
  },
});
