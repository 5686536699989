
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["update:modelValue", "blur"],
  props: {
    modelValue: {
      required: false,
      default: null,
      validator: (val: any): boolean =>
        val === null || typeof val === "string" || typeof val === "number",
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    rows: {
      type: Number as PropType<number>,
      default: 3,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: {
    changeModelValue(event: InputEvent): void {
      this.$emit(
        "update:modelValue",
        (event.target as HTMLTextAreaElement).value
      );
    },
    focusOut(): void {
      this.$emit("blur");
    },
  },
});
