
import { defineComponent, PropType } from "vue";
import { mapActions, mapState } from "pinia";
import { TournamentResult } from "@/models/tournament-result.model";
import { useTournamentResultsStore } from "@/stores/tournament-results";
//import CreateOrEditTournamentResultForm from "../forms/CreateOrEditTournamentResultForm.vue";

export default defineComponent({
  components: {
    //CreateOrEditTournamentResultForm,
  },
  props: {
    tournamentId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      selectedTournamentResult: null as TournamentResult | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      tableActions: [
        {
          key: "tournament-results/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "tournament-results/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useTournamentResultsStore, [
      "paginatedTournamentResults",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingTournamentResults",
      "tournamentResultsHasChanged",
    ]),
    currentId(): string | null {
      if (this.selectedTournamentResult && this.isEditModalOpen) {
        return this.selectedTournamentResult.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useTournamentResultsStore, ["fetchTournamentResults"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadTournamentResults(pageNumber: number): void {
      this.fetchTournamentResults(this.tournamentId, pageNumber);
    },
    setSelectedTournamentResult(tournamentResult?: TournamentResult): void {
      if (tournamentResult) {
        this.selectedTournamentResult = tournamentResult;
      }
    },
    clearSelectedTournamentResult(): void {
      this.selectedTournamentResult = null;
    },
    openPopover(index: number): void {
      const tournamentResult = this.paginatedTournamentResults[index];
      this.setSelectedTournamentResult(tournamentResult);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedTournamentResult();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "tournament-rules/edit":
          this.toggleEditModal();
          break;
        case "tournament-rules/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
  },
  watch: {
    tournamentResultsHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchTournamentResults(this.tournamentId);
      }
    },
  },
  mounted(): void {
    this.fetchTournamentResults(this.tournamentId);
  },
});
