
import { CreateOrEditTournamentDTO } from "./interfaces/CreateOrEditTournamentDTO";
import { defineComponent, PropType } from "vue";
import { format } from "date-fns";
import { mapActions, mapState } from "pinia";
import { useAppOptionsStore } from "@/stores/app-options";
import { useTournamentsStore } from "@/stores/tournaments";
import MultipleDevicesFinder from "@/components/app/devices/finders/MultipleDevicesFinder.vue";
import MultipleGamesFinder from "@/components/app/games/finders/MultipleGamesFinder.vue";
import MultipleTournamentRulesFinder from "@/components/app/tournament-rules/finders/MultipleTournamentRulesFinder.vue";
import MultipleTournamentSystemsFinder from "@/components/app/tournament-systems/finders/MultipleTournamentSystemsFinder.vue";
import TournamentPrizesFormList from "@/components/app/tournament-prizes/forms/TournamentPrizesFormList.vue";

export default defineComponent({
  emits: ["submit-create", "submit-edit", "after-submit"],
  components: {
    MultipleGamesFinder,
    MultipleDevicesFinder,
    MultipleTournamentRulesFinder,
    MultipleTournamentSystemsFinder,
    TournamentPrizesFormList,
  },
  props: {
    currentId: {
      type: String as PropType<string | null>,
    },
    noHandleSubmit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      tournamentDTO: {
        title: "",
        description: "",
        place: "",
        startDate: null,
        endDate: null,
        createdByPerson: {
          id: null,
        },
        tournamentType: {
          id: null,
        },
        tournamentFormat: {
          id: null,
        },
        tournamentPrice: {
          symbol: null,
          amount: null,
        },
        tournamentPrizes: [],
        games: [],
        devices: [],
        tournamentSystems: [],
        tournamentRules: [],
        tournamentImageFile: null,
        image: {
          id: null,
          url: null,
        },
      } as CreateOrEditTournamentDTO,
      isSubmitted: false,
    };
  },
  computed: {
    ...mapState(useTournamentsStore, [
      "selectedTournament",
      "isCreateTournamentLoading",
      "isGetSelectedTournamentLoading",
      "isUpdateSelectedTournamentLoading",
    ]),
    ...mapState(useAppOptionsStore, ["tournamentTypes", "tournamentFormats"]),
    isLoading(): boolean {
      return (
        this.isCreateTournamentLoading ||
        this.isGetSelectedTournamentLoading ||
        this.isUpdateSelectedTournamentLoading
      );
    },
    isValidTitle(): boolean {
      if (this.isSubmitted) {
        return this.tournamentDTO.title.trim() !== "";
      }
      return true;
    },
    isValidDescription(): boolean {
      if (this.isSubmitted) {
        return this.tournamentDTO.description.trim() !== "";
      }
      return true;
    },
    isValidPlace(): boolean {
      if (this.isSubmitted) {
        return this.tournamentDTO.place.trim() !== "";
      }
      return true;
    },
    isValidStartDate(): boolean {
      if (this.isSubmitted) {
        return this.tournamentDTO.startDate !== null;
      }
      return true;
    },
    isValidEndDate(): boolean {
      if (this.isSubmitted) {
        return this.tournamentDTO.endDate !== null;
      }
      return true;
    },
    isValidPrice(): boolean {
      if (this.isSubmitted) {
        return this.tournamentDTO.tournamentPrice.amount !== null;
      }
      return true;
    },
    isValidForm(): boolean {
      return (
        this.isValidTitle &&
        this.isValidDescription &&
        this.isValidPlace &&
        this.isValidStartDate &&
        this.isValidEndDate &&
        this.isValidPrice
      );
    },
    isEditing(): boolean {
      return (
        this.currentId !== null &&
        this.currentId !== undefined &&
        typeof this.currentId === "string"
      );
    },
    submitButtonColor(): string {
      return this.isEditing ? "success" : "primary";
    },
    submitButtonText(): string {
      return this.isEditing ? "Modificar Torneo" : "Crear Torneo";
    },
  },
  methods: {
    ...mapActions(useTournamentsStore, [
      "createTournament",
      "selectTournament",
      "updateSelectedTournament",
    ]),
    ...mapActions(useAppOptionsStore, [
      "fetchTournamentTypes",
      "fetchTournamentFormats",
    ]),
    async submitForm(): Promise<void> {
      this.isSubmitted = true;

      if (!this.isValidForm) {
        return;
      }

      if (this.isEditing) {
        await this.handleUpdate();
      } else {
        await this.handleCreate();
      }
      this.$emit("after-submit");
    },
    async handleUpdate(): Promise<void> {
      if (this.noHandleSubmit) {
        this.$emit("submit-edit", {
          id: this.currentId,
          data: { ...this.tournamentDTO },
        });
      } else {
        await this.updateSelectedTournament(this.tournamentDTO);
      }
    },
    async handleCreate(): Promise<void> {
      if (this.noHandleSubmit) {
        this.$emit("submit-create", {
          data: { ...this.tournamentDTO },
        });
      } else {
        await this.createTournament(this.tournamentDTO);
      }
    },
  },
  watch: {
    selectedTournament(): void {
      if (this.selectedTournament) {
        this.tournamentDTO.title = this.selectedTournament.title;
        this.tournamentDTO.description = this.selectedTournament.description;
        this.tournamentDTO.place = this.selectedTournament.place;
        this.tournamentDTO.tournamentType =
          this.selectedTournament.tournamentType;
        this.tournamentDTO.tournamentFormat =
          this.selectedTournament.tournamentFormat;
        this.tournamentDTO.tournamentPrice =
          this.selectedTournament.tournamentPrice;
        this.tournamentDTO.games = this.selectedTournament.games;
        this.tournamentDTO.devices = this.selectedTournament.devices;
        this.tournamentDTO.tournamentRules =
          this.selectedTournament.tournamentRules;
        this.tournamentDTO.tournamentSystems =
          this.selectedTournament.tournamentSystems;
        this.tournamentDTO.tournamentPrizes =
          this.selectedTournament.tournamentPrizes;
        this.tournamentDTO.startDate = format(
          this.selectedTournament.startDate,
          "yyyy-MM-dd'T'HH:mm"
        );
        this.tournamentDTO.endDate = format(
          this.selectedTournament.endDate,
          "yyyy-MM-dd'T'HH:mm"
        );

        if (this.selectedTournament.image) {
          this.tournamentDTO.image.id = this.selectedTournament.image.id;
          this.tournamentDTO.image.url = this.selectedTournament.image.url;
        }
      }
    },
  },
  mounted(): void {
    this.fetchTournamentTypes();
    this.fetchTournamentFormats();
    if (this.isEditing) {
      this.selectTournament(this.currentId as string);
    }
  },
});
