import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("aside", null, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullSidebarLinks, (link) => {
        return (_openBlock(), _createElementBlock("li", {
          key: link.name
        }, [
          _createVNode(_component_router_link, {
            to: link.path,
            class: _normalizeClass(_ctx.buildClasses(link))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(link.name), 1),
              _createVNode(_component_base_icon, {
                prefix: link.icon.prefix,
                icon: link.icon.name
              }, null, 8, ["prefix", "icon"])
            ]),
            _: 2
          }, 1032, ["to", "class"])
        ]))
      }), 128))
    ])
  ]))
}