
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    previousSrc: {
      type: String as PropType<string>,
      required: false,
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      inputValue: null as string | null,
    };
  },
  computed: {
    currentSrc(): string {
      return URL.createObjectURL(this.modelValue as Blob);
    },
  },
  methods: {
    changeModelValue(event: InputEvent): void {
      if ((event.target as HTMLInputElement).value) {
        this.inputValue = (event.target as HTMLInputElement).value;
        this.$emit(
          "update:modelValue",
          (event.target as HTMLInputElement).files![0]
        );
      }
    },
    selectFiles(): void {
      (this.$refs["input-tag"] as HTMLInputElement).click();
    },
  },
});
