
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    elements: {
      type: Array as PropType<string[]>,
      required: true,
    },
    maxHeight: {
      type: String as PropType<string>,
      required: false,
    },
    prefix: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    baseSimpleListStyles(): object {
      return {
        "max-height": this.maxHeight,
      };
    },
  },
});
