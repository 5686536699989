
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String as PropType<string>,
      default: "primary",
      validator: (val: string): boolean => {
        const colors = ["primary", "secondary", "success", "danger", "warning"];
        return colors.indexOf(val) !== -1;
      },
    },
  },
  computed: {
    badgeClasses(): object {
      return {
        [this.color]: !!this.color,
      };
    },
  },
});
