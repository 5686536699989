
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { TournamentType } from "@/models/tournament-type.model";
import { useTournamentTypesStore } from "@/stores/tournament-types";
import CreateOrEditTournamentTypeForm from "../forms/CreateOrEditTournamentTypeForm.vue";

export default defineComponent({
  components: {
    CreateOrEditTournamentTypeForm,
  },
  data() {
    return {
      selectedTournamentType: null as TournamentType | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      tableActions: [
        {
          key: "tournament-types/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "tournament-types/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useTournamentTypesStore, [
      "paginatedTournamentTypes",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingTournamentTypes",
      "tournamentTypesHasChanged",
    ]),
    currentId(): string | null {
      if (this.selectedTournamentType && this.isEditModalOpen) {
        return this.selectedTournamentType.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useTournamentTypesStore, ["fetchTournamentTypes"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadTournamentTypes(pageNumber: number): void {
      this.fetchTournamentTypes(pageNumber);
    },
    setSelectedTournamentType(tournamentType?: TournamentType): void {
      if (tournamentType) {
        this.selectedTournamentType = tournamentType;
      }
    },
    clearSelectedTournamentType(): void {
      this.selectedTournamentType = null;
    },
    openPopover(index: number): void {
      const tournamentType = this.paginatedTournamentTypes[index];
      this.setSelectedTournamentType(tournamentType);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedTournamentType();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "tournament-types/edit":
          this.toggleEditModal();
          break;
        case "tournament-types/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
  },
  watch: {
    tournamentTypesHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchTournamentTypes();
      }
    },
  },
  mounted(): void {
    this.fetchTournamentTypes();
  },
});
