
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { TournamentSystem } from "@/models/tournament-system.model";
import { useTournamentSystemsStore } from "@/stores/tournament-systems";
import CreateOrEditTournamentSystemForm from "../forms/CreateOrEditTournamentSystemForm.vue";

export default defineComponent({
  components: {
    CreateOrEditTournamentSystemForm,
  },
  data() {
    return {
      selectedTournamentSystem: null as TournamentSystem | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      tableActions: [
        {
          key: "tournament-systems/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "tournament-systems/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useTournamentSystemsStore, [
      "paginatedTournamentSystems",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingTournamentSystems",
      "tournamentSystemsHasChanged",
    ]),
    currentId(): string | null {
      if (this.selectedTournamentSystem && this.isEditModalOpen) {
        return this.selectedTournamentSystem.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useTournamentSystemsStore, ["fetchTournamentSystems"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadTournamentSystems(pageNumber: number): void {
      this.fetchTournamentSystems(pageNumber);
    },
    setSelectedTournamentSystem(tournamentSystem?: TournamentSystem): void {
      if (tournamentSystem) {
        this.selectedTournamentSystem = tournamentSystem;
      }
    },
    clearSelectedTournamentSystem(): void {
      this.selectedTournamentSystem = null;
    },
    openPopover(index: number): void {
      const tournamentSystem = this.paginatedTournamentSystems[index];
      this.setSelectedTournamentSystem(tournamentSystem);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedTournamentSystem();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "tournament-systems/edit":
          this.toggleEditModal();
          break;
        case "tournament-systems/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
  },
  watch: {
    tournamentSystemsHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchTournamentSystems();
      }
    },
  },
  mounted(): void {
    this.fetchTournamentSystems();
  },
});
