import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.buttonClasses)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_component_base_icon, {
      icon: "times",
      onClick: _ctx.remove,
      class: "icon"
    }, null, 8, ["onClick"])
  ], 2))
}