
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { Game } from "@/models/game.model";
import { useGamesStore } from "@/stores/games";

export default defineComponent({
  data() {
    return {
      selectedGame: null as Game | null,
      isPopoverOpen: false,
      tableActions: [
        {
          key: "games/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "games/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useGamesStore, [
      "paginatedGames",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingGames",
    ]),
  },
  methods: {
    ...mapActions(useGamesStore, ["fetchGames"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadGames(pageNumber: number): void {
      this.fetchGames(pageNumber);
    },
    setSelectedGame(game?: Game): void {
      if (game) {
        this.selectedGame = game;
      }
    },
    clearSelectedGame(): void {
      this.selectedGame = null;
    },
    openPopover(index: number): void {
      const game = this.paginatedGames[index];
      this.setSelectedGame(game);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedGame();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "game/edit":
          // TODO: Handler edit
          break;
        case "game/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
  },
  mounted(): void {
    this.fetchGames();
  },
});
