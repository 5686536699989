import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4feaa29e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pagination"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.totalPages !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            disabled: !_ctx.canPrev,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toPrevPage && _ctx.toPrevPage(...args)))
          }, "Prev", 8, _hoisted_2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentButtonNumbers, (page) => {
            return (_openBlock(), _createElementBlock("button", {
              key: page,
              class: _normalizeClass({ 'current-page': page === _ctx.currentPage }),
              onClick: ($event: any) => (_ctx.toPage(page))
            }, _toDisplayString(page), 11, _hoisted_3))
          }), 128)),
          _createElementVNode("button", {
            disabled: !_ctx.canNext,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toNextPage && _ctx.toNextPage(...args)))
          }, "Sig", 8, _hoisted_4)
        ])
      ]))
    : _createCommentVNode("", true)
}