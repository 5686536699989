import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiple_finder_input = _resolveComponent("multiple-finder-input")!

  return (_openBlock(), _createBlock(_component_multiple_finder_input, {
    onInput: _ctx.filterOptions,
    modelValue: _ctx.selectedOptions,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOptions) = $event)),
    onChange: _ctx.updateModelValue,
    options: _ctx.gameOptions
  }, null, 8, ["onInput", "modelValue", "onChange", "options"]))
}