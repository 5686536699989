
import TournamentSystemsTable from "@/components/app/tournament-systems/tables/TournamentSystemsTable.vue";
import CreateOrEditTournamentSystemForm from "@/components/app/tournament-systems/forms/CreateOrEditTournamentSystemForm.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    TournamentSystemsTable,
    CreateOrEditTournamentSystemForm,
  },
  data() {
    return {
      isCreateModalOpen: false,
    };
  },
  methods: {
    toggleCreateModal() {
      this.isCreateModalOpen = !this.isCreateModalOpen;
    },
    closeModal(): void {
      this.toggleCreateModal();
    },
  },
});
