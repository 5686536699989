
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["selectAction"],
  props: {
    actions: {
      type: Array as PropType<
        {
          name: string;
          key: string;
          icon: { name: string; type: string };
        }[]
      >,
      required: true,
    },
  },
  methods: {
    selectAction(actionKey: string) {
      this.$emit("selectAction", actionKey);
    },
  },
});
