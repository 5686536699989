
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { TournamentInscription } from "@/models/tournament-inscription.model";
import { useTournamentInscriptionsStore } from "@/stores/tournament-inscriptions";
import EditPokemonShowdownTeamExportForm from "../forms/EditPokemonShowdownTeamExportForm.vue";
import EditTournamentInscriptionStatusForm from "../forms/EditTournamentInscriptionStatusForm.vue";

export default defineComponent({
  components: {
    EditPokemonShowdownTeamExportForm,
    EditTournamentInscriptionStatusForm,
  },
  data() {
    return {
      selectedTournamentInscription: null as TournamentInscription | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      isEditStatusModalOpen: false,
      tableActions: [
        {
          key: "tournament-inscriptions/edit-team",
          name: "Editar Equipo",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "tournament-inscriptions/change-status",
          name: "Cambiar estado",
          icon: { name: "exchange-alt", type: "fas" },
        },
        {
          key: "tournament-inscriptions/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useTournamentInscriptionsStore, [
      "paginatedTournamentInscriptions",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingTournamentInscriptions",
      "tournamentInscriptionsHasChanged",
    ]),
    currentId(): string | null {
      if (
        this.selectedTournamentInscription &&
        (this.isEditModalOpen || this.isEditStatusModalOpen)
      ) {
        return this.selectedTournamentInscription.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useTournamentInscriptionsStore, [
      "fetchTournamentInscriptions",
    ]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadTournamentInscriptions(pageNumber: number): void {
      this.fetchTournamentInscriptions(pageNumber);
    },
    setSelectedTournamentInscription(
      tournamentInscription?: TournamentInscription
    ): void {
      if (tournamentInscription) {
        this.selectedTournamentInscription = tournamentInscription;
      }
    },
    clearSelectedTournamentInscription(): void {
      this.selectedTournamentInscription = null;
    },
    openPopover(index: number): void {
      const tournamentInscription = this.paginatedTournamentInscriptions[index];
      this.setSelectedTournamentInscription(tournamentInscription);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedTournamentInscription();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "tournament-inscriptions/edit-team":
          this.toggleEditModal();
          break;
        case "tournament-inscriptions/change-status":
          this.toggleEditStatusModal();
          break;
        case "tournament-inscriptions/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
    toggleEditStatusModal(): void {
      this.isEditStatusModalOpen = !this.isEditStatusModalOpen;
    },
  },
  watch: {
    tournamentInscriptionsHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchTournamentInscriptions();
      }
    },
  },
  mounted(): void {
    this.fetchTournamentInscriptions();
  },
});
