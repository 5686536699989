import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ea9e31c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
      return (_openBlock(), _createElementBlock("li", {
        key: action.name
      }, [
        _createElementVNode("a", {
          href: action.key.toLowerCase(),
          onClick: _withModifiers(($event: any) => (_ctx.selectAction(action.key)), ["prevent"])
        }, [
          _createElementVNode("span", null, _toDisplayString(action.name), 1),
          _createVNode(_component_base_icon, {
            icon: action.icon.name,
            type: action.icon.type
          }, null, 8, ["icon", "type"])
        ], 8, _hoisted_1)
      ]))
    }), 128))
  ]))
}