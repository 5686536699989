
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";

export default defineComponent({
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState(useAuthStore, ["isLoggedIn", "isLoadingLogin"]),
    logoImageAsset() {
      return require("@/assets/img/logo.png");
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["loginUser"]),
    async submit(): Promise<void> {
      await this.loginUser({
        email: this.email,
        password: this.password,
      });
    },
    clearForm(): void {
      this.email = "";
      this.password = "";
    },
  },
  watch: {
    isLoggedIn() {
      this.clearForm();
      this.$router.push({
        name: "Home",
      });
    },
  },
});
