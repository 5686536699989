
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { Person } from "@/models/person.model";
import { usePeopleStore } from "@/stores/people";

export default defineComponent({
  data() {
    return {
      selectedPerson: null as Person | null,
      isPopoverOpen: false,
      isDeleteIngredientModalOpen: false,
      tableActions: [
        {
          key: "people/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "people/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(usePeopleStore, [
      "paginatedPeople",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingPeople",
    ]),
  },
  methods: {
    ...mapActions(usePeopleStore, ["fetchPeople"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadPeople(pageNumber: number): void {
      this.fetchPeople(pageNumber);
    },
    setSelectedPerson(person?: Person): void {
      if (person) {
        this.selectedPerson = person;
      }
    },
    clearSelectedPerson(): void {
      this.selectedPerson = null;
    },
    openPopover(index: number): void {
      const person = this.paginatedPeople[index];
      this.setSelectedPerson(person);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedPerson();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "person/edit":
          // TODO: Handler edit
          break;
        case "person/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
  },
  mounted(): void {
    this.fetchPeople();
  },
});
