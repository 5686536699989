
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { Role } from "@/models/role.model";
import { useRolesStore } from "@/stores/roles";
import CreateOrEditRoleForm from "../forms/CreateOrEditRoleForm.vue";

export default defineComponent({
  components: {
    CreateOrEditRoleForm,
  },
  data() {
    return {
      selectedRole: null as Role | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      tableActions: [
        {
          key: "roles/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "roles/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useRolesStore, [
      "paginatedRoles",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingRoles",
      "rolesHasChanged",
    ]),
    currentId(): string | null {
      if (this.selectedRole && this.isEditModalOpen) {
        return this.selectedRole.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useRolesStore, ["fetchRoles"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadRoles(pageNumber: number): void {
      this.fetchRoles(pageNumber);
    },
    setSelectedRole(role?: Role): void {
      if (role) {
        this.selectedRole = role;
      }
    },
    clearSelectedRole(): void {
      this.selectedRole = null;
    },
    openPopover(index: number): void {
      const role = this.paginatedRoles[index];
      this.setSelectedRole(role);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedRole();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "roles/edit":
          this.toggleEditModal();
          break;
        case "roles/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
  },
  watch: {
    rolesHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchRoles();
      }
    },
  },
  mounted(): void {
    this.fetchRoles();
  },
});
