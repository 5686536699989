
import { useAuthStore } from "@/stores/auth";
import { mapActions, mapState } from "pinia";
import Vue, { defineComponent } from "vue";
export default defineComponent({
  computed: {
    ...mapState(useAuthStore, ["loggedUser", "isLoggedIn"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["logout"]),
  },
});
