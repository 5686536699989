
import { useTournamentSystemsStore } from "@/stores/tournament-systems";
import { mapActions, mapState } from "pinia";
import { defineComponent, PropType } from "vue";
import { CreateOrEditTournamentSystemDTO } from "./interfaces/CreateOrEditTournamentSystemDTO";

export default defineComponent({
  emits: ["submit-create", "submit-edit", "after-submit"],
  props: {
    currentId: {
      type: String as PropType<string | null>,
    },
    noHandleSubmit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      tournamentSystemDTO: {
        name: "",
        description: "",
      } as CreateOrEditTournamentSystemDTO,
      isSubmitted: false,
    };
  },
  computed: {
    ...mapState(useTournamentSystemsStore, [
      "selectedTournamentSystem",
      "isCreateTournamentSystemLoading",
      "isGetSelectedTournamentSystemLoading",
      "isUpdateSelectedTournamentSystemLoading",
    ]),
    isLoading(): boolean {
      return (
        this.isCreateTournamentSystemLoading ||
        this.isGetSelectedTournamentSystemLoading ||
        this.isUpdateSelectedTournamentSystemLoading
      );
    },
    isValidName(): boolean {
      if (this.isSubmitted) {
        return this.tournamentSystemDTO.name !== "";
      }
      return true;
    },
    isValidDescription(): boolean {
      if (this.isSubmitted) {
        return this.tournamentSystemDTO.description !== "";
      }
      return true;
    },
    isValidForm(): boolean {
      return this.isValidName && this.isValidDescription;
    },
    isEditing(): boolean {
      return (
        this.currentId !== null &&
        this.currentId !== undefined &&
        typeof this.currentId === "string"
      );
    },
    submitButtonColor(): string {
      return this.isEditing ? "success" : "primary";
    },
    submitButtonText(): string {
      return this.isEditing
        ? "Modificar Sistema de Torneo"
        : "Crear Sistema de Torneo";
    },
  },
  methods: {
    ...mapActions(useTournamentSystemsStore, [
      "createTournamentSystem",
      "selectTournamentSystem",
      "updateSelectedTournamentSystem",
    ]),
    async submitForm(): Promise<void> {
      this.isSubmitted = true;

      if (!this.isValidForm) {
        return;
      }

      if (this.isEditing) {
        await this.handleUpdate();
      } else {
        await this.handleCreate();
      }
      this.$emit("after-submit");
    },
    async handleUpdate(): Promise<void> {
      if (this.noHandleSubmit) {
        this.$emit("submit-edit", {
          id: this.currentId,
          data: { ...this.tournamentSystemDTO },
        });
      } else {
        await this.updateSelectedTournamentSystem({
          ...this.tournamentSystemDTO,
        });
      }
    },
    async handleCreate(): Promise<void> {
      if (this.noHandleSubmit) {
        this.$emit("submit-create", {
          data: { ...this.tournamentSystemDTO },
        });
      } else {
        await this.createTournamentSystem({ ...this.tournamentSystemDTO });
      }
    },
  },
  watch: {
    selectedTournamentSystem(): void {
      if (this.selectedTournamentSystem) {
        this.tournamentSystemDTO.name = this.selectedTournamentSystem.name;
        this.tournamentSystemDTO.description =
          this.selectedTournamentSystem.description;
      }
    },
  },
  mounted(): void {
    if (this.isEditing) {
      this.selectTournamentSystem(this.currentId as string);
    }
  },
});
