
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { SmallTournament } from "@/models/small-tournament.model";
import { useTournamentsStore } from "@/stores/tournaments";

export default defineComponent({
  data() {
    return {
      selectedTournament: null as SmallTournament | null,
      isPopoverOpen: false,
      tableActions: [
        {
          key: "tournaments/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "tournaments/results",
          name: "Resultados",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "tournaments/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useTournamentsStore, [
      "paginatedTournaments",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingTournaments",
    ]),
  },
  methods: {
    ...mapActions(useTournamentsStore, ["fetchTournaments"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadTournaments(pageNumber: number): void {
      this.fetchTournaments(pageNumber);
    },
    setSelectedTournament(tournament?: SmallTournament): void {
      if (tournament) {
        this.selectedTournament = tournament;
      }
    },
    clearSelectedTournament(): void {
      this.selectedTournament = null;
    },
    openPopover(index: number): void {
      const tournament = this.paginatedTournaments[index];
      this.setSelectedTournament(tournament);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedTournament();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "tournaments/edit":
          this.$router.push({
            name: "EditTournament",
            params: {
              tournamentId: this.selectedTournament!.id,
            },
          });
          break;
        case "tournaments/results":
          this.$router.push({
            name: "TournamentResults",
            params: {
              tournamentId: this.selectedTournament!.id,
            },
          });
          break;
        case "tournaments/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
  },
  mounted(): void {
    this.fetchTournaments();
  },
});
