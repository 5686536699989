import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e0ea5ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "backdrop",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("dialog", {
              key: 0,
              open: "",
              class: _normalizeClass(_ctx.classStyles)
            }, [
              _createElementVNode("header", null, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
                  _createVNode(_component_base_icon, {
                    type: "fas",
                    icon: "times",
                    class: "times",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                  })
                ], true)
              ]),
              _createElementVNode("section", null, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              (_ctx.footered)
                ? (_openBlock(), _createElementBlock("menu", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}