import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f528df3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "home-view" }
const _hoisted_2 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_navbar = _resolveComponent("the-navbar")!
  const _component_the_sidebar = _resolveComponent("the-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_the_navbar, { id: "navbar" }),
    _createElementVNode("main", null, [
      _createVNode(_component_the_sidebar, { id: "sidebar" }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}