
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { TournamentRule } from "@/models/tournament-rule.model";
import { useTournamentRulesStore } from "@/stores/tournament-rules";
import CreateOrEditTournamentRuleForm from "../forms/CreateOrEditTournamentRuleForm.vue";

export default defineComponent({
  components: {
    CreateOrEditTournamentRuleForm,
  },
  data() {
    return {
      selectedTournamentRule: null as TournamentRule | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      tableActions: [
        {
          key: "tournament-rules/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "tournament-rules/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useTournamentRulesStore, [
      "paginatedTournamentRules",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingTournamentRules",
      "tournamentRulesHasChanged",
    ]),
    currentId(): string | null {
      if (this.selectedTournamentRule && this.isEditModalOpen) {
        return this.selectedTournamentRule.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useTournamentRulesStore, ["fetchTournamentRules"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadTournamentRules(pageNumber: number): void {
      this.fetchTournamentRules(pageNumber);
    },
    setSelectedTournamentRule(tournamentRule?: TournamentRule): void {
      if (tournamentRule) {
        this.selectedTournamentRule = tournamentRule;
      }
    },
    clearSelectedTournamentRule(): void {
      this.selectedTournamentRule = null;
    },
    openPopover(index: number): void {
      const tournamentRule = this.paginatedTournamentRules[index];
      this.setSelectedTournamentRule(tournamentRule);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedTournamentRule();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "tournament-rules/edit":
          this.toggleEditModal();
          break;
        case "tournament-rules/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
  },
  watch: {
    tournamentRulesHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchTournamentRules();
      }
    },
  },
  mounted(): void {
    this.fetchTournamentRules();
  },
});
