
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["select-page"],
  props: {
    currentPage: {
      type: Number as PropType<number>,
      default: 1,
    },
    totalPages: {
      type: Number as PropType<number>,
      default: 1,
    },
    blockPages: {
      type: Number as PropType<number>,
      default: 5,
    },
  },
  computed: {
    maxButtonBlocks(): number {
      if (this.totalPages % this.blockPages === 0) {
        return this.totalPages / this.blockPages;
      }
      return ((this.totalPages / this.blockPages) << 0) + 1;
    },
    currentButtonBlock(): number {
      if (this.currentPage % this.blockPages === 0) {
        return this.currentPage / this.blockPages;
      }
      return ((this.currentPage / this.blockPages) << 0) + 1;
    },
    currentButtonNumbers(): number[] {
      const buttonNumbers: number[] = [];
      const [lastButtonNumberOfBlock, firstButtonNumberOfBlock] = (() => {
        if (this.currentButtonBlock === this.maxButtonBlocks) {
          return [
            this.totalPages,
            this.currentButtonBlock * this.blockPages - this.blockPages + 1,
          ];
        }
        return [
          this.currentButtonBlock * this.blockPages,
          this.currentButtonBlock * this.blockPages - this.blockPages + 1,
        ];
      })();
      for (
        let i = firstButtonNumberOfBlock;
        i <= lastButtonNumberOfBlock;
        i++
      ) {
        buttonNumbers.push(i);
      }
      return buttonNumbers;
    },
    canNext(): boolean {
      return this.currentPage != this.totalPages;
    },
    canPrev(): boolean {
      return this.currentPage != 1;
    },
  },
  methods: {
    toPage(page: number): void {
      this.$emit("select-page", page);
    },
    toPrevPage(): void {
      this.toPage(this.currentPage - 1);
    },
    toNextPage(): void {
      this.toPage(this.currentPage + 1);
    },
  },
});
