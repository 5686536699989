
import RolesTable from "@/components/app/roles/tables/RolesTable.vue";
import CreateOrEditRoleForm from "@/components/app/roles/forms/CreateOrEditRoleForm.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    RolesTable,
    CreateOrEditRoleForm,
  },
  data() {
    return {
      isCreateModalOpen: false,
    };
  },
  methods: {
    toggleCreateModal() {
      this.isCreateModalOpen = !this.isCreateModalOpen;
    },
    closeModal(): void {
      this.toggleCreateModal();
    },
  },
});
