
import TournamentResultsTable from "@/components/app/tournament-results/tables/TournamentResultsTable.vue";
import { useTournamentsStore } from "@/stores/tournaments";
import { mapActions, mapState } from "pinia";
import { defineComponent, PropType } from "vue";
import CreateOrEditTournamentResultForm from "@/components/app/tournament-results/forms/CreateOrEditTournamentResultForm.vue";

export default defineComponent({
  components: {
    TournamentResultsTable,
    CreateOrEditTournamentResultForm,
  },
  props: {
    tournamentId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isCreateModalOpen: false,
    };
  },
  computed: {
    ...mapState(useTournamentsStore, ["selectedTournament"]),
    tournamentTitle(): string {
      if (this.selectedTournament) {
        return this.selectedTournament.title;
      }

      return "-";
    },
  },
  methods: {
    ...mapActions(useTournamentsStore, ["selectTournament"]),
    toggleCreateModal() {
      this.isCreateModalOpen = !this.isCreateModalOpen;
    },
    closeModal(): void {
      this.toggleCreateModal();
    },
  },
  mounted(): void {
    this.selectTournament(this.tournamentId);
  },
});
