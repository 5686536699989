import { renderSlot as _renderSlot, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "popover",
    style: _normalizeStyle(_ctx.popoverStyles),
    ref: "popover"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4)), [
    [_vShow, _ctx.open]
  ])
}