<template>
  <router-view></router-view>
</template>

<style lang="scss">
* {
  font-family: Roboto;
  font-weight: normal;
}

html,
body,
#app {
  font-family: Roboto;
  width: 100%;
  height: 100%;
}
</style>
