
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["update:modelValue", "blur", "focus"],
  props: {
    modelValue: {
      required: false,
      default: null,
      validator: (val): boolean =>
        val === null || typeof val === "string" || typeof val === "number",
    },
    type: {
      type: String as PropType<string>,
      default: "text",
      required: false,
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: {
    changeModelValue(event: InputEvent): void {
      let value: string | number = (event.target as HTMLInputElement).value;
      if (this.type === "number") {
        value = Number(value);
      }
      this.$emit("update:modelValue", value);
    },
    focusOut(event: InputEvent): void {
      this.$emit("blur", event);
    },
    focusIn(event: InputEvent): void {
      this.$emit("focus", event);
    },
  },
});
