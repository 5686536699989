
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number] as PropType<string | number>,
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  methods: {
    changeModelValue(event: InputEvent): void {
      this.$emit("update:modelValue", (event.target as HTMLInputElement).value);
    },
  },
});
