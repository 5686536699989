
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "BaseButton",
  props: {
    block: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    color: {
      type: String,
      default: "primary",
      validator: (val: string): boolean => {
        const colors = ["primary", "danger", "success", "warning"];
        return colors.indexOf(val) !== -1;
      },
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
  },
  computed: {
    buttonClasses(): object {
      return {
        "btn-block": this.block,
        [this.color]: true,
      };
    },
  },
});
