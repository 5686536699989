
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    message: {
      type: String as PropType<string>,
      default: "Sin elementos",
    },
  },
});
