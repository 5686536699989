
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useNavigationStore } from "@/stores/navigation";
import { SidebarLink } from "@/stores/navigation/types";

export default defineComponent({
  computed: {
    ...mapState(useNavigationStore, ["fullSidebarLinks"]),
  },
  methods: {
    buildClasses(link: SidebarLink): { "selected-link": boolean } {
      return {
        "selected-link": link.path === this.$route.path,
      };
    },
  },
  mounted() {
    console.log(this.fullSidebarLinks);
  },
});
