
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["update:modelValue", "input"],
  props: {
    modelValue: {
      required: false,
      default: null,
      validator: (val) => val === null || typeof val === "object",
    },
    /**
     * Options has a format: [{key: Number, value: String}, ...]
     */
    options: {
      type: Array as PropType<{ key: number; value: string }[]>,
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  data() {
    return {
      inputValue: "",
      isOptionsVisible: false,
    };
  },
  methods: {
    changeModelValue(option: { key: number; value: string }): void {
      this.$emit("update:modelValue", { ...option });
    },
    emitInput(value: string): void {
      this.$emit("input", value);
    },
    showOptions(): void {
      this.isOptionsVisible = true;
    },
    hideOptions(): void {
      this.isOptionsVisible = false;
    },
  },
});
