
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["close"],
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
    },
    open: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    type: {
      type: String as PropType<string>,
      required: false,
      default: "medium",
      validator: (val: string): boolean => {
        const values = ["tiny", "small", "medium", "large"];
        return values.indexOf(val) !== -1;
      },
    },
    footered: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    classStyles(): object {
      return {
        [this.type]: !!this.type,
      };
    },
  },
});
