
import DevicesTable from "@/components/app/devices/tables/DevicesTable.vue";
import CreateOrEditDeviceForm from "@/components/app/devices/forms/CreateOrEditDeviceForm.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DevicesTable,
    CreateOrEditDeviceForm,
  },
  data() {
    return {
      isCreateModalOpen: false,
    };
  },
  methods: {
    toggleCreateModal() {
      this.isCreateModalOpen = !this.isCreateModalOpen;
    },
    closeModal(): void {
      this.toggleCreateModal();
    },
  },
});
