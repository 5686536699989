
import TournamentsTable from "@/components/app/tournaments/tables/TournamentsTable.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    TournamentsTable,
  },
  computed: {
    isListing(): boolean {
      return !(
        this.$route.path.includes("create") || this.$route.path.includes("edit")
      );
    },
    isListingResults(): boolean {
      return this.$route.path.includes("results");
    },
  },
  methods: {
    toView(): void {
      this.$router.push({
        name: "CreateTournament",
      });
    },
  },
});
