import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edb04358"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_finder_input = _resolveComponent("finder-input")!
  const _component_base_chip = _resolveComponent("base-chip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_finder_input, {
      "onUpdate:modelValue": _ctx.addSelectedOption,
      onInput: _ctx.handleInput,
      options: _ctx.unselectedOptions,
      "is-valid": _ctx.isValid
    }, null, 8, ["onUpdate:modelValue", "onInput", "options", "is-valid"]),
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (option, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.key
            }, [
              _createVNode(_component_base_chip, {
                onClickRemove: ($event: any) => (_ctx.removeSelectedOption(index)),
                color: _ctx.chipColor
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(option.value), 1)
                ]),
                _: 2
              }, 1032, ["onClickRemove", "color"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}