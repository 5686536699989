
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["click-remove"],
  props: {
    block: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    color: {
      type: String as PropType<string>,
      default: "primary",
      validator: (val: string): boolean => {
        const colors = ["primary", "danger", "success", "warning"];
        return colors.indexOf(val) !== -1;
      },
    },
  },
  computed: {
    buttonClasses(): object {
      return {
        block: this.block,
        [this.color]: true,
        chip: true,
      };
    },
  },
  methods: {
    remove(): void {
      this.$emit("click-remove");
    },
  },
});
