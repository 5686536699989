
import { defineComponent, PropType } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<
        {
          id?: string;
          title: string;
          description: string;
        }[]
      >,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      title: "",
      description: "",
    };
  },
  computed: {
    canAdd(): boolean {
      return this.title.trim() !== "" && this.description.trim() !== "";
    },
  },
  methods: {
    addPrize(): void {
      const newPrize = {
        title: this.title,
        description: this.description,
      };
      this.$emit("update:modelValue", [...this.modelValue, newPrize]);
      //this.prizes.push(newPrize);
    },
    removePrize(index: number): void {
      const prizes = [...this.modelValue];
      prizes.splice(index, 1);
      this.$emit("update:modelValue", prizes);
    },
  },
});
