import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57609aa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_input, {
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _ctx.emitInput,
      onFocus: _ctx.showOptions,
      onBlur: _ctx.hideOptions,
      "is-valid": _ctx.isOptionsVisible || _ctx.isValid
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onFocus", "onBlur", "is-valid"]),
    _withDirectives(_createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          key: option.key,
          onMousedown: ($event: any) => (_ctx.changeModelValue(option)),
          ref_for: true,
          ref: "options"
        }, _toDisplayString(option.value), 41, _hoisted_1))
      }), 128))
    ], 512), [
      [_vShow, _ctx.isOptionsVisible]
    ])
  ]))
}