
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { GameGeneration } from "@/models/game-generation.model";
import { useGameGenerationsStore } from "@/stores/game-generations";
import CreateOrEditGameGenerationForm from "../forms/CreateOrEditGameGenerationForm.vue";

export default defineComponent({
  components: {
    CreateOrEditGameGenerationForm,
  },
  data() {
    return {
      selectedGameGeneration: null as GameGeneration | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      tableActions: [
        {
          key: "game-generations/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "game-generations/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useGameGenerationsStore, [
      "paginatedGameGenerations",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingGameGenerations",
      "gameGenerationsHasChanged",
    ]),
    currentId(): string | null {
      if (this.selectedGameGeneration && this.isEditModalOpen) {
        return this.selectedGameGeneration.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useGameGenerationsStore, ["fetchGameGenerations"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadGameGenerations(pageNumber: number): void {
      this.fetchGameGenerations(pageNumber);
    },
    setSelectedGameGeneration(gameGeneration?: GameGeneration): void {
      if (gameGeneration) {
        this.selectedGameGeneration = gameGeneration;
      }
    },
    clearSelectedGameGeneration(): void {
      this.selectedGameGeneration = null;
    },
    openPopover(index: number): void {
      const gameGeneration = this.paginatedGameGenerations[index];
      this.setSelectedGameGeneration(gameGeneration);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedGameGeneration();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "game-generations/edit":
          this.toggleEditModal();
          break;
        case "game-generations/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
  },
  watch: {
    gameGenerationsHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchGameGenerations();
      }
    },
  },
  mounted(): void {
    this.fetchGameGenerations();
  },
});
