
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { Device } from "@/models/device.model";
import { useDevicesStore } from "@/stores/devices";
import CreateOrEditDeviceForm from "../forms/CreateOrEditDeviceForm.vue";

export default defineComponent({
  components: {
    CreateOrEditDeviceForm,
  },
  data() {
    return {
      selectedDevice: null as Device | null,
      isPopoverOpen: false,
      isEditModalOpen: false,
      tableActions: [
        {
          key: "devices/edit",
          name: "Editar",
          icon: { name: "edit", type: "fas" },
        },
        {
          key: "devices/delete",
          name: "Eliminar",
          icon: { name: "trash-alt", type: "fas" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useDevicesStore, [
      "paginatedDevices",
      "totalPages",
      "currentPage",
      "perPage",
      "isLoadingDevices",
      "devicesHasChanged",
    ]),
    currentId(): string | null {
      if (this.selectedDevice && this.isEditModalOpen) {
        return this.selectedDevice.id;
      }

      return null;
    },
  },
  methods: {
    ...mapActions(useDevicesStore, ["fetchDevices"]),
    orderNumber(index: number): number {
      return index + 1 + this.perPage * (this.currentPage - 1);
    },
    loadDevices(pageNumber: number): void {
      this.fetchDevices(pageNumber);
    },
    setSelectedDevice(device?: Device): void {
      if (device) {
        this.selectedDevice = device;
      }
    },
    clearSelectedDevice(): void {
      this.selectedDevice = null;
    },
    openPopover(index: number): void {
      const device = this.paginatedDevices[index];
      this.setSelectedDevice(device);
      this.isPopoverOpen = true;
    },
    closePopover(): void {
      this.clearSelectedDevice();
      this.isPopoverOpen = false;
    },
    onSelectAction(actionKey: string): void {
      switch (actionKey) {
        case "devices/edit":
          this.toggleEditModal();
          break;
        case "devices/delete":
          // TODO: Handler delete
          break;
      }
      this.isPopoverOpen = false;
    },
    toggleEditModal(): void {
      this.isEditModalOpen = !this.isEditModalOpen;
    },
  },
  watch: {
    devicesHasChanged(changed: boolean): void {
      if (changed) {
        this.fetchDevices();
      }
    },
  },
  mounted(): void {
    this.fetchDevices();
  },
});
