
import TournamentRulesTable from "@/components/app/tournament-rules/tables/TournamentRulesTable.vue";
import CreateOrEditTournamentRuleForm from "@/components/app/tournament-rules/forms/CreateOrEditTournamentRuleForm.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    TournamentRulesTable,
    CreateOrEditTournamentRuleForm,
  },
  data() {
    return {
      isCreateModalOpen: false,
    };
  },
  methods: {
    toggleCreateModal() {
      this.isCreateModalOpen = !this.isCreateModalOpen;
    },
    closeModal(): void {
      this.toggleCreateModal();
    },
  },
});
