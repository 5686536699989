import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-369cc54b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    style: _normalizeStyle(_ctx.baseSimpleListStyles)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element) => {
      return (_openBlock(), _createElementBlock("li", { key: element }, [
        (_ctx.prefix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.prefix), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(element), 1)
      ]))
    }), 128))
  ], 4))
}